import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  ImageField,
  FilterDropdown,
  Select,
  useSelect,
  Row,
  Col,
  Form,
  Icons,
  Input,
  Button,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { Chat } from "../../interfaces/Chat";
import "../../styles/index.css";

export const ChatList: React.FC = () => {
  const { tableProps, searchFormProps } = useTable<Chat, HttpError, { username: string; content: string }>({
    syncWithLocation: true,
    initialSorter: [{ field: "createdAt", order: "desc" }],
    initialPageSize: 20,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { username, content } = params;

          filters.push(
              {
                  field: "username",
                  operator: "eq",
                  value: username,
              },
              {
                  field: "message",
                  operator: "eq",
                  value: content,
              },
          );

      return filters;
  },
  });

  return (
    <>
      
      <List title={<Row wrap={false}>
        <Col span={10}>
            Chats
        </Col>
        <Col span={10}>
          <Form layout="inline" {...searchFormProps}>
            <Form.Item label="Username" name="username">
              <Input
                  placeholder="Username"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label="Content" name="content">
              <Input
                  placeholder="Content"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>}>
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="userId" render={(value) => {
                  return <ImageField height={'60px'} width={'60px'} value={`https://www.roblox.com/headshot-thumbnail/image?userId=${value}&width=60&height=60&format=png`} />
              }} />
              <Table.Column 
                dataIndex='name' 
                title='Username'
                sorter
                render={(value) => <TagField value={value} />}
              />
              <Table.Column
                  dataIndex="createdAt"
                  title="Sent At"
                  sorter
                  defaultSortOrder={'descend'}
                  render={(value) => <DateField format="LLL" value={value} />}
              />
              <Table.Column
                  dataIndex="message"
                  title="Message"
                  render={(value) => <TextField value={value} />}
              />
          </Table>
      </List>
    </>
  );
};