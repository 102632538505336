import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  if(config.url?.match('order=asc')) {
    config.url = config.url.replace('order=asc', 'order=ASC');
  }
  if(config.url?.match('order=desc')) {
    config.url = config.url.replace('order=desc', 'order=DESC');
  }
  return config;
})

export const TOKEN_KEY = "refine-auth";

export const authProvider : AuthProvider = {
  login: ({ username, password }) =>  {
      const request = new Request(`${process.env.REACT_APP_API_URL ?? 'https://potato-host.com/v1'}/auth/login`, {
          method: 'POST',
          body: JSON.stringify({ username, password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      let response: Response;
      return fetch(request)
        .then(res => {
          response = res;
          return res.json();
        })
        .then(auth => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(auth.message);
          }
          axiosInstance.defaults.headers.common = {
            'Authorization': `${auth.authToken}`
          }
          localStorage.setItem('auth', auth.authToken);
        })
  },
  checkAuth: () => {
    const authToken = localStorage.getItem('auth')
    if(authToken) {
      axiosInstance.defaults.headers.common = {
        'Authorization': `${authToken}`
      }
      return Promise.resolve();
    }
    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
        localStorage.removeItem('auth');
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  logout: () => {
      localStorage.removeItem('auth');
      return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(['admin']),
};
