import { useShow, useOne } from "@pankod/refine-core";
import { Show, Typography, Tag, Image } from "@pankod/refine-antd";

import { User } from "../../interfaces/User";

const { Title, Text } = Typography;

export const UserShow = () => {
    const { queryResult } = useShow();
    console.log(queryResult);
    const { data, isLoading } = queryResult;
    const record = data?.data as User;
    
    // const { data: categoryData } = useOne<User>({
    //     resource: "categories",
    //     id: record?.category.id || "",
    //     queryOptions: {
    //         enabled: !!record?.category.id,
    //     },
    // });

    if(!record) {
        return <Show isLoading={isLoading} />
    }

    return (
        <Show isLoading={isLoading}>
            <Title level={2}>{record.key.split(';')[0]}</Title>
            <Image src={`https://www.roblox.com/headshot-thumbnail/image?userId=${record.key.split(';')[1]}&width=150&height=150&format=png`} />
            <br /><br />
            <Title level={3}>Stats</Title>
            <Title level={5}>Case Bux</Title>
            <Text>{record?.sortedBux}</Text>
            <Title level={5}>RAP</Title>
            <Text>{record?.sortedRap}</Text>
            <Title level={5}>Level</Title>
            <Text>{record?.sortedLevel}</Text>
            <Title level={5}>Item Amount</Title>
            <Text>{record?.sortedItemAmount}</Text>
        </Show>
    );
};