import { Card, Col, Show, Title, Typography } from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";
import { MarketTransaction } from "interfaces/MarketTransaction";
import { useEffect, useState } from "react";

const MakeItem = ({ image, item } : { image: string | null, item: MarketTransaction["boughtItem"] }) => {
    return (
        <Col>
            <Card
                style={{ width: 200 }}
                cover={<div style={{width: 200}}>
                    <h2 style={{position: 'absolute', right: '15px', marginTop: '15px', color: 'black'}}>x{item.Amount}</h2>
                    <img style={{backgroundColor: '#FF0000', width: '200px'}} src={(
                        image ?? 'https://t4.rbxcdn.com/8a6b406d7fa205564b2079962f83b253') as string} 
                    />
                </div>}
            >
                <p><b>{item.Name}</b></p>
            </Card>
        </Col>
    )
}

export function MarketShow() {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const [image, setImage] = useState<string | null>(null);
    const record = data?.data as MarketTransaction;

    useEffect(() => {
        if(!record) return;
        fetch(`https://thumbnails.roproxy.com/v1/assets?assetIds=${record.boughtItem.AssetId}&returnPolicy=PlaceHolder&size=512x512&format=Png&isCircular=false`)
        .then(res => res.json())
        .then(res => {
            const imgMap = new Map<number, string>();
            setImage(res.data[0]?.imageUrl);
        });
    }, [record])

    if(!record) {
        return <Show isLoading={isLoading} />;
    }

    

    return (
        <Show isLoading={isLoading}>
            <Typography.Title level={2}>Marketplace Transaction</Typography.Title>
            <Typography.Title level={3}>Sold by</Typography.Title>
            <Typography.Text>{record.seller}</Typography.Text>
            <Typography.Title level={3}>Bought by</Typography.Title>
            <Typography.Text>{record.buyer}</Typography.Text>
            <Typography.Title level={3}>Total Price</Typography.Title>
            <Typography.Text>{record.price}</Typography.Text>
            <Typography.Title level={3}>Per Piece Price</Typography.Title>
            <Typography.Text>{record.price / record.boughtItem.Amount}</Typography.Text>
            <br /><br />
            <MakeItem image={image} item={record.boughtItem} />
        </Show>
    )


    
}