import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  ImageField,
  ShowButton,
  Button,
  Col,
  Form,
  Icons,
  Input,
  Row,
  NumberField,
  Tooltip,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { Trade } from "interfaces/Trade";
import { TradeItem } from "interfaces/TradeItem";

export const TradeList: React.FC = () => {
  const { tableProps, searchFormProps, filters } = useTable<Trade, HttpError, {sender: string, receiver: string, either: string, item: string}>({
    syncWithLocation: true,
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      }
    ],
    initialPageSize: 20,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { sender, receiver, either, item } = params;

      filters.push(
          {
              field: "sender",
              operator: "eq",
              value: sender,
          },
          {
              field: "receiver",
              operator: "eq",
              value: receiver,
          },
          {
              field: "either",
              operator: "eq",
              value: either,
          },
          {
              field: "item",
              operator: "eq",
              value: item,
          },
      );

      return filters;
    },
  });

  const itemFilter = filters?.find((f) => {
    const x = f as {field: string, operator: string, value: string};
    return x.field === "item" && x.value !== '';
  })
  
  console.log(itemFilter);
  return (
      <List title={<Row wrap={false}>
      <Col span={10}>
          Trades
      </Col>
      <Col span={10}>
        <Form layout="inline" {...searchFormProps}>
          <Form.Item label="Sender" name="sender">
            <Input
                placeholder="Username"
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item label="Receiver" name="receiver">
            <Input
                placeholder="Username"
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item label="Either" name="either">
            <Input
                placeholder="Username"
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item label="Item" name="item">
            <Input
                placeholder="Item name"
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>}>
          <Table {...tableProps} rowKey="id">
              <Table.Column
                  dataIndex="createdAt"
                  title="Sent At"
                  sorter
                  defaultSortOrder={'descend'}
                  render={(value) => <DateField format="LLL" value={value} />}
              />
              <Table.Column 
                dataIndex='sender' 
                title='From'
                defaultSortOrder={'ascend'}
                render={(value) => <TagField value={value} />}
              />
              <Table.Column
                  dataIndex="receiver"
                  title="To"
                  render={(value) => <TagField value={value} />}
              />
              <Table.Column
                dataIndex="sentItems"
                title="Items sent"
                render={(value) => <Tooltip placement="right" title={(() => {
                  if(!itemFilter) {
                    return false;
                  }
                  const item = value.items.find((item: TradeItem) => {
                    return typeof(item) !== 'number' && item.Name.toLowerCase().includes(itemFilter.value) ? true : false
                  });
                  return item ? item.Name : '';
                })()} visible={(() => {
                  if(!itemFilter) {
                    return false;
                  }
                  return value.items.find((item: TradeItem) => {
                    return typeof(item) !== 'number' && item.Name.toLowerCase().includes(itemFilter.value) ? true : false
                  });
                })()}>
                    <NumberField value={value.items.length - 1} />
                    {value.bux > 0 && <TagField color={"green"} style={{marginLeft: '5px'}} value="+ CB"/> }
                  
                  </Tooltip>}
              />
              <Table.Column
                dataIndex="receivedItems"
                title="Items received"
                render={(value) => <Tooltip placement="right" title={(() => {
                  if(!itemFilter) {
                    return false;
                  }
                  const item = value.items.find((item: TradeItem) => {
                    return typeof(item) !== 'number' && item.Name.toLowerCase().includes(itemFilter.value) ? true : false
                  });
                  return item ? item.Name : '';
                })()} visible={(() => {
                  if(!itemFilter) {
                    return false;
                  }
                  return value.items.find((item: TradeItem) => {
                    return typeof(item) !== 'number' && item.Name.toLowerCase().includes(itemFilter.value) ? true : false
                  });
                })()}>
                    <NumberField value={value.items.length - 1} />
                    {value.bux > 0 && <TagField color={"green"} style={{marginLeft: '5px'}} value="+ CB"/> }
                  
                  </Tooltip>}
              />
              <Table.Column<Trade>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <a href={`/trade/show/${record.id}`}>
                      <Button icon={<Icons.EyeOutlined />} />
                    </a>
                    // <ShowButton
                    //   size="small"
                    //   recordItemId={record.id}
                    //   hideText
                    // />
                  );
                }}
            />
          </Table>
      </List>
  );
};