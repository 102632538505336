import { useShow, useOne } from "@pankod/refine-core";
import { Show, Typography, Tag, Image, Row, Col, Card } from "@pankod/refine-antd";

import { Trade } from "../../interfaces/Trade";
import { useEffect, useReducer, useState } from "react";
import { TradeItem } from "interfaces/TradeItem";
import React from "react";

const assetUrl = "https://www.roblox.com/Thumbs/Asset.ashx?width=150&height=150&assetId=";

const { Title, Text } = Typography;

const MakeItem = (imgMap: Map<number, string> | null) => {
    const imageMap = imgMap ?? new Map<number, string>();
    return function(item: TradeItem) {
        if(typeof(item) === 'number') {
            return;
        }

        const amount = item.Amount === 1 && item.Mult > 1 ? item.Mult : item.Amount;
        return <Col>
            <Card
                style={{ width: 200 }}
                cover={<div style={{width: 200}}>
                    <h2 style={{position: 'absolute', right: '15px', marginTop: '15px', color: 'black'}}>x{amount}</h2>
                    <img style={{backgroundColor: '#FF0000', width: '200px'}} src={(
                        imageMap.get(item.AssetId) 
                        ? imageMap.get(item.AssetId)
                        : 'https://t4.rbxcdn.com/8a6b406d7fa205564b2079962f83b253') as string} 
                    />
                </div>}
            >
                <p><b>{item.Name}</b></p>
            </Card>
        </Col>
    }
}

export const TradeShow = () => {
    const { queryResult } = useShow();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({} as any), []);
    const { data, isLoading } = queryResult;
    const [ imageMap, setImageMap ] = useState<Map<number, string> | null>(null);
    const record = data?.data as Trade;

    // useEffect(() => {
    //     console.log("what");
    //     console.log(record);
    // }, [record]);

    useEffect(() => {
        if(imageMap) {
            return;
        };
        if(!record) {
            return;
        }
        record.sentItems.items.forEach((item: any, index: number) => {
            if(typeof(item) === 'number') {
                return;
            }
            if(index === record.sentItems.items.length - 1) {
                endString += item.AssetId;
                return;
            }
            endString += item.AssetId + '%2C%20';
        })
    
        record.receivedItems.items.forEach((item: any, index: number) => {
            if(typeof(item) === 'number') {
                return;
            }
            if(index === record.receivedItems.items.length - 1) {
                endString += item.AssetId;
                return;
            }
            endString += item.AssetId + '%2C%20';
        })
    
        fetch(`https://thumbnails.roproxy.com/v1/assets?assetIds=${endString}&returnPolicy=PlaceHolder&size=512x512&format=Png&isCircular=false`)
        .then(res => res.json())
        .then(res => {
            const imgMap = new Map<number, string>();
            res.data.forEach((asset: any) => {
                imgMap.set(asset.targetId, asset.imageUrl);
            });
            setImageMap(imgMap);
        });
    }, [record, imageMap])

    console.log(imageMap);

    if(!record) {
        return <Show isLoading={isLoading} />
    }

    let endString = '';    

    return (
        <Show isLoading={isLoading}>
            <Title level={2}>{record.sender} - {record.receiver} {`(Trade #${record.id})`}</Title>
            <Row>
                <Col span={12}>
                    <Title level={3}>What {record.sender} sent</Title>
                </Col>
                <Col span={12}>
                    <Title level={3}>What {record.receiver} sent</Title>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12} style={{maxHeight: '400px', overflow: 'auto'}}>
                    <Row gutter={[16, 16]}>
                        {
                            record.sentItems.items.map(MakeItem(imageMap))
                        }
                    </Row>
                </Col>
                <Col span={12} style={{maxHeight: '400px', overflow: 'auto'}}>
                    <Row gutter={[16, 16]}>
                        {
                            record.receivedItems.items.map(MakeItem(imageMap))
                        }
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Title style={{textAlign: 'center'}} level={3}>+ <span style={{color: '#00AA00'}}>{record.sentItems.items[0]} CB</span></Title>
                </Col>
                <Col span={12}>
                    <Title style={{textAlign: 'center'}} level={3}>+ <span style={{color: '#00AA00'}}>{record.receivedItems.items[0]} CB</span></Title>
                </Col>
            </Row>
        </Show>
    );
};