import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  ImageField,
  ShowButton,
  Button,
  Col,
  Form,
  Icons,
  Input,
  Row,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { User } from "interfaces/User";

export const UserList: React.FC = () => {
  const { tableProps, searchFormProps } = useTable<User, HttpError, {username: string}>({
    syncWithLocation: true,
    initialSorter: [
      {
        field: "key",
        order: "asc",
      }
    ],
    initialPageSize: 20,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { username } = params;

      filters.push(
          {
              field: "username",
              operator: "eq",
              value: username,
          },
      );

      return filters;
    },
  });

  return (
      <List title={<Row wrap={false}>
      <Col span={10}>
          Users
      </Col>
      <Col span={10}>
        <Form layout="inline" {...searchFormProps}>
          <Form.Item label="Username" name="username">
            <Input
                placeholder="Username/UserID"
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>}>
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="key" render={(value) => {
                  return <ImageField height={'60px'} width={'60px'} value={`https://www.roblox.com/headshot-thumbnail/image?userId=${value.split(';')[1]}&width=60&height=60&format=png`} />
              }} />
              <Table.Column 
                dataIndex='key' 
                title='Username'
                defaultSortOrder={'ascend'}
                sorter
                render={(value) => <TagField value={value.split(';')[0]} />}
              />
              <Table.Column
                  dataIndex="value"
                  title="Value"
                  sorter
                  render={(value) => <TagField value={value} />}
              />
              <Table.Column<User>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <a href={`${window.location.pathname}/show/${record.id}`}>
                      <Button icon={<Icons.EyeOutlined />} />
                    </a>
                  );
                }}
            />
          </Table>
      </List>
  );
};