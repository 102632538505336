import {
    List,
    TextField,
    TagField,
    DateField,
    Table,
    useTable,
    ImageField,
    ShowButton,
    Button,
    Col,
    Form,
    Icons,
    Input,
    Row,
    NumberField,
    Tooltip,
  } from "@pankod/refine-antd";
  import { CrudFilters, HttpError } from "@pankod/refine-core";
import { MarketTransaction } from "interfaces/MarketTransaction";
  import { Trade } from "interfaces/Trade";
  import { TradeItem } from "interfaces/TradeItem";
  
  export const MarketList: React.FC = () => {
    const { tableProps, searchFormProps, filters } = useTable<MarketTransaction, HttpError, {seller: string, buyer: string, either: string, item: string}>({
      syncWithLocation: true,
      initialSorter: [
        {
          field: "createdAt",
          order: "desc",
        }
      ],
      initialPageSize: 20,
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { seller, buyer, either, item } = params;
  
        filters.push(
            {
                field: "seller",
                operator: "eq",
                value: seller,
            },
            {
                field: "buyer",
                operator: "eq",
                value: buyer,
            },
            {
                field: "either",
                operator: "eq",
                value: either,
            },
            {
                field: "item",
                operator: "eq",
                value: item,
            },
        );
  
        return filters;
      },
    });
  
    const itemFilter = filters?.find((f) => {
      const x = f as {field: string, operator: string, value: string};
      return x.field === "item" && x.value !== '';
    })
    
    console.log(itemFilter);
    return (
        <List title={<Row wrap={false}>
        <Col span={10}>
            Marketplace Transactions
        </Col>
        <Col span={10}>
          <Form layout="inline" {...searchFormProps}>
            <Form.Item label="Seller" name="seller">
              <Input
                  placeholder="Username"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label="Buyer" name="buyer">
              <Input
                  placeholder="Username"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label="Either" name="either">
              <Input
                  placeholder="Username"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label="Item" name="item">
              <Input
                  placeholder="Item name"
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>}>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="createdAt"
                    title="Sent At"
                    sorter
                    defaultSortOrder={'descend'}
                    render={(value) => <DateField format="LLL" value={value} />}
                />
                <Table.Column 
                  dataIndex='seller' 
                  title='From'
                  defaultSortOrder={'ascend'}
                  render={(value) => <TagField value={value} />}
                />
                <Table.Column
                    dataIndex="buyer"
                    title="To"
                    render={(value) => <TagField value={value} />}
                />

                <Table.Column
                    dataIndex="price"
                    title="Total"
                    sorter
                    render={(value) => <NumberField value={value} />}
                />
                <Table.Column<Trade>
                  title="Actions"
                  dataIndex="actions"
                  render={(_text, record): React.ReactNode => {
                    return (
                      <a href={`/market/show/${record.id}`}>
                        <Button icon={<Icons.EyeOutlined />} />
                      </a>
                      // <ShowButton
                      //   size="small"
                      //   recordItemId={record.id}
                      //   hideText
                      // />
                    );
                  }}
              />
            </Table>
        </List>
    );
  };