import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  LoginPage,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider, axiosInstance } from "./authProvider";
import { ChatList } from "pages/Chats/ChatList";
import { UserList } from "pages/User/UserList";
import { UserShow } from "pages/User/UserShow";
import { TradeList } from "pages/Trade/TradeList";
import { TradeShow } from "pages/Trade/TradeShow";
import { MarketList } from "pages/Market/MarketList";
import { MarketShow } from "pages/Market/MarketShow";

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={routerProvider}
      dataProvider={dataProvider(process.env.REACT_APP_API_URL ?? 'https://potato-host.com/v1', axiosInstance)}
      authProvider={authProvider}
      LoginPage={LoginPage}
      Title={() => <h1
      style={{
        width: '200px',
        padding: '12px 24px',
        color: '#ffffff',
        fontSize: '40px',
        marginBottom: 0,
        fontFamily: "McLaren"
      }}>RoLog</h1>}
      resources={[
        {
          name: 'chat',
          list: ChatList
        },
        {
          name: 'user',
          list: UserList,
          show: UserShow,
          canDelete: true,
        },
        {
          name: 'tc_user',
          list: UserList,
          show: UserShow,
          canDelete: true,
        },
        {
          name: 'trade',
          list: TradeList,
          show: TradeShow,
          canDelete: true,
        },
        {
          name: 'market',
          options: {
            label: 'Marketplace'
          },
          list: MarketList,
          show: MarketShow,
          canDelete: true,
        }
      ]}
    />
  );
}

export default App;
